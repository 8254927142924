import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SiteContentService {

  constructor(public api: ApiService) { }

  public SiteContent = this.api.http.all('site-contents');
  public Content = this.api.http.all('contents');
  public Gallery = this.api.http.all('galleries');
  public Testimonial = this.api.http.all('testimonials');


  getOneContent(slug:string){
    return this.SiteContent.get(slug);
  }

  getEvents(filters:any){
    return this.Content.getList(filters);
  }

  getEvent(id:Number){
    return this.Content.get(id);
  }

  getAllContent(slug:string){
    return this.SiteContent.getList({'slug': slug});
  }

  getTestimonials(){
    return this.Testimonial.getList({'status': 'A'})
  }

  getContentByType(type:string){
    return this.Content.getList({'type': type});
  }

  getGalleries(gallery_type:string, gallery_id:Number){
    return this.Gallery.getList({'gallery_type': gallery_type, 'gallery_id': gallery_id});
  }

}
